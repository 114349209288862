@import '~@travel-ui/styles/src/utility';

.buttonSection {
  width: 100%;
  padding: $spacing-16 $spacing-16 0;
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.rfbButton {
  min-width: 100%;
  text-align: left;
  padding: $spacing-08 $spacing-12;
  @include font-pc-header-h6-bold;
  border-radius: 4px;
  svg path[fill] {
    fill: $cilantro;
  }
}
.divider {
  border-bottom: 1px solid $pastel-gray;
  margin-top: $spacing-08;
}
.rfbButtonLabel {
  margin-left: $spacing-08;
  text-align: left;
}
