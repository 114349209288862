@import '~@travel-ui/styles/src/utility';

.skeleton {
  background-color: $isabelline;
  position: relative;
  overflow: hidden;
  height: 20px;

  &:before {
    content: '';
    display: block;
    height: 100%;
    width: 100%;
    background: linear-gradient(90deg, transparent, rgba($white, 0.4), transparent);
    position: absolute;
    top: 0;
    left: 0;
    animation: skeleton-animation 1.2s linear infinite;
  }
}

@keyframes skeleton-animation {
  0% {
    transform: translateX(-100%);
  }
  100% {
    transform: translateX(100%);
  }
}

.paragraph {
  overflow: hidden;

  &.short {
    width: 75%;
  }

  &.long {
    width: 100%;
  }

  div:not(:last-child) {
    margin-bottom: 10px;
  }
}
