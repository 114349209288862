@import '~@travel-ui/styles/src/utility';

.tag {
  @include border-radius-all($border-radius-4px);
  display: inline-block;
  font-size: $font-mars;
  height: auto;
  min-width: 20px;
  padding: $spacing-04 $spacing-08;
  width: auto;
  background-color: $white;
}

.outlined {
  &-purple {
    border: 1px solid $cyber-grape;
    color: $cyber-grape;
  }

  &-red {
    border: 1px solid $apple-red;
    color: $apple-red;
  }

  &-yellow {
    border: 1px solid $sunflower;
    color: $sunflower;
  }

  &-green {
    border: 1px solid $cilantro;
    color: $cilantro;
  }

  &-gray {
    border: 1px solid $isabelline;
    color: $isabelline;
  }

  &-silver {
    border: 1px solid $sonic-silver;
    color: $sonic-silver;
  }

  &-blue {
    border: 1px solid $blue-raspberry;
    color: $blue-raspberry;
  }

  &-jet {
    border: 1px solid $jet;
    color: $jet;
  }

  &-white {
    border: 1px solid $white;
    color: $white;
  }

  &-scarlet {
    border: 1px solid $scarlet;
    color: $scarlet;
  }
  &-lincolnGreen {
    border: 1px solid $lincoln-green;
    color: $lincoln-green;
  }
}

.filled {
  &-purple {
    background: $cyber-grape;
    color: $white;
  }

  &-red {
    background: $apple-red;
    color: $white;
  }

  &-yellow {
    background: $sunflower;
    color: $jet;
  }

  &-green {
    background: $cilantro;
    color: $white;
  }

  &-gray {
    background: $isabelline;
    color: $jet;
  }

  &-silver {
    background: $sonic-silver;
    color: $white;
  }

  &-blue {
    background: $blue-raspberry;
    color: $white;
  }

  &-jet {
    background: $jet;
    color: $white;
  }

  &-white {
    background: $white;
    color: $jet;
  }

  &-scarlet {
    background: $scarlet;
    color: $white;
  }
  &-lincolnGreen {
    background: $lincoln-green;
    color: $white;
  }
}
