@import '~@travel-ui/styles/src/utility';

.wrapper {
  position: relative;

  .loader {
    position: sticky;
    background-color: $cilantro;
    height: 4px;

    width: 100%;

    &:before {
      content: '';
      display: block;
      height: 100%;
      width: 100%;
      background: linear-gradient(90deg, transparent 0%, $sunflower 50%, transparent 100%);
      animation: skeleton-animation 0.6s linear infinite;
    }
  }
}
