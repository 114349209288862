@import '~@travel-ui/styles/src/utility';

.container {
  padding: 12px $spacing-m 0 $spacing-m;

  .label {
    font-weight: bold;
    line-height: 20px;
    margin-bottom: 12px;
  }
}
