@import '~@travel-ui/styles/src/utility';

$triangle-rotate: 135deg;
$full: 100%;
$ball-minH: 120px;
$box-minH: 115px;
$triangle-size: 8px;
$triangle-size-half: 4px;

.balloon {
  @include box-shadow-middle;
  @include border-radius;

  position: relative;
  width: $full;
  height: calc(100% - #{$triangle-size-half});
  background-color: $white;
  border: 1px solid $pastel-gray;
  z-index: $zIndex-dropdown;

  .text {
    position: relative;

    a {
      display: block;
      width: 100%;
      height: 100%;
      text-decoration: none;
      color: $jet;
    }
  }
}

.triangle {
  @include box($triangle-size);

  position: absolute;
  border: 1px solid transparent;
  background-color: $white;
  transform: rotate($triangle-rotate);
}

.ballMinH {
  min-height: $ball-minH;
  padding: $spacing-m $spacing-base;
}

.textMinH {
  min-height: $box-minH;
}

.topTriangle {
  border-left-color: $pastel-gray;
  border-bottom-color: $pastel-gray;
  top: -$triangle-size-half;
}

.bottomTriangle {
  border-right-color: $pastel-gray;
  border-top-color: $pastel-gray;
  bottom: -$triangle-size-half;
}

.topLeft,
.topCenter,
.topRight {
  .triangle {
    @extend .topTriangle;
  }
}

.bottomLeft,
.bottomCenter,
.bottomRight {
  .triangle {
    @extend .bottomTriangle;
  }
}

.topLeft,
.bottomLeft {
  .triangle {
    left: $spacing-m;
  }
}

.topCenter,
.bottomCenter {
  .triangle {
    left: 50%;
    transform: translateX(-50%) rotate($triangle-rotate);
  }
}

.topRight,
.bottomRight {
  .triangle {
    right: $spacing-m;
  }
}
