@import './colors';

// screensize breakpoints
$sp-min: 320px;
$sp-max: 743px;
$tl-min: 744px;
$tl-max: 1024px;
$pc-min: 1025px;
$pc-max: 1200px;

// z-index
$zIndex-rockBottom: -1;
$zIndex-overlay1: 1; // Ex. Carousel's arrow, overlap border
$zIndex-overlay2: 2;
$zIndex-overlay3: 3;
$zIndex-adsExpandable: 150;
$zIndex-adsFloating: 250;
$zIndex-popup: 350;
$zIndex-floating: 450;
$zIndex-dropdown: 550; // Ex. dropdown menu, selectbox
$zIndex-modal: 650; // Ex. Fullscreen carousel, FullscreenDialog
$z-index-header: 999;
$z-index-sidebar: 9999;

// supplier exclusive variables
$fixed-button-height: 88px;
$supplier-header-height: 72px;
$supplier-toolbar-height: 57px;
$supplier-footer-height: 44px;
$supplier-sidebar-width: 272px;
$supplier-page-header-height: 130px;
$supplier-fixed-button-height: 88px;
$supplier-collapsedSideMenuWidth: 67px;
$supplier-sticky-header-height: 60px;
$extranet-small-pc-width: 1579px;
$bookingTable-normal-width: 1768px;
$bookingTable-extranet-width: 1512px;
$bookingTable-extranet-small-pc-width: 1212px;

// dialog
$dialog-header-height: 56px;
$dialog-actionbar-height: 60px;

// traveler
$traveler-header-height-sp: 59px;
$traveler-header-height-pc: 70px;

// ratio
$ratioWidth16by9: 1.77777777778; // 16/9
$ratioHeight16by9: 0.5625; // 9/16
$ratioWidth4by3: 1.33333333333; // 4/3
$ratioHeight4by3: 0.75; // 3/4
$ratioWidth3by2: 1.5; // 3/2
$ratioHeight3by2: 0.6666666667; // 2/3

// Filter Balloon
$filter-wrapper-width: 382px;
$filter-action-button-width: 166px;

// --------------------
// Deprecated
// --------------------

// Font scale
$font-solar: 24px;
$font-jupiter: 22px;
$font-saturn: 20px;
$font-uranus: 18px;
$font-neptune: 16px;
$font-venus: 14px;
$font-mars: 12px;
$font-terra: 10px;
$font-luna: 8px;

// Line height
$line-height-title: 1.25;
$line-height-title-sp: 1.27;
$line-height-sub-title: 1.2;
$line-height-sub-title-sp: 1.22;
$line-height-category: 1.5;
$line-height-label: 1.43;
$line-height-sub-label: 1.5;
$line-height-body: 1.43;
$line-height-sub-body: 1.5;
$line-height-caption: 1.66;

// spacing
$spacing-xl: 32px;
$spacing-l: 24px;
$spacing-m: 16px;
$spacing-hl: 12px;
$spacing-base: 8px;
$spacing-s: 4px;

// Border radius
$border-radius: 3px;
