@import '~@travel-ui/styles/src/utility';

.skeletonContent {
  height: 20px;
  width: 320px;
  margin-left: 16px;
}

.wrapper {
  table {
    td {
      vertical-align: middle;
      height: 75px;
    }
  }
}
