@import '~@travel-ui/styles/src/utility';

$circular-radius: 24px;

.button {
  @include resetButtonStyle;
  @include border-radius;
  @include font-label;
}

.buttonSizeDefault {
  min-height: 44px;
  min-width: 90px;
  padding: $spacing-s $spacing-l;
  // Default for pc and tl size
  max-width: 33.3%;

  @include media(sp) {
    max-width: 100%;
  }
}

.buttonSizeImage {
  // Default for tl and sp size
  @include box(70px, 36px);

  @include media(pc) {
    @include box(150px, 44px);
  }
}

.smallerButton {
  min-height: 36px;
}

.flexWrapper {
  display: block;
  text-align: center;
  vertical-align: middle;
}

.iconDivDefault {
  align-items: center;
  display: inline-flex;
}

.textWithIconWrapper {
  display: inline-block;
  margin-left: 5px;
}

.primary {
  @include hoverStyle(background-color, $lincoln-green);

  background-color: $cilantro;
  color: $white;

  .iconFill {
    g > g {
      fill: $white;
    }
  }
}

.secondary {
  @include hoverStyle(border-color, $lincoln-green);
  @include hoverStyle('color', $lincoln-green);

  border: 1px solid $cilantro;
  background-color: $white;
  color: $cilantro;

  .iconFill {
    g > g {
      fill: $cilantro;
    }
  }
}

.tertiary {
  @include hoverStyle(border-color, $shady-cardinal);
  @include hoverStyle('color', $shady-cardinal);

  border: 1px solid $apple-red;
  background-color: $white;
  color: $apple-red;

  .iconFill {
    g > g {
      fill: $apple-red;
    }
  }
}

.skeleton {
  border: 2px solid $isabelline;
  color: $pastel-gray;
  cursor: default;
}

.flat {
  @include hoverStyle(border-color, $cilantro);

  background-color: $white;
  color: $jet;
  border: 1px solid $pastel-gray;

  .iconFill {
    g > g {
      fill: $jet;
    }
  }
}

.image {
  border: 1px solid $pastel-gray;
  color: $jet;

  .iconFill {
    g > path:last-child {
      fill: $apple-red;
    }
  }
}

.disabled {
  background-color: $isabelline;
  color: $pastel-gray;
  cursor: default;

  .iconFill {
    g > g {
      fill: $pastel-gray;
    }
  }
}

.circular {
  display: inline-block;
  animation: rotate 1s linear infinite;
  transform-origin: center center;
  width: $circular-radius;
  height: $circular-radius;
  fill: $olivine;

  &.isLoading {
    fill: $cilantro;

    & > .loadingPrimary {
      fill: $shady-white;
    }
  }
}

.critical {
  background-color: $scarlet;
  color: $white;
  cursor: pointer;

  .iconFill {
    g > g {
      fill: $white;
    }
  }

  &:hover,
  &:active {
    background-color: $shady-cardinal;
  }
}

.furusato {
  @extend .critical;
  background-color: $crimson;
}

.rakutenMobile {
  @extend .critical;
  background-color: $bright-pink;

  &:hover,
  &:active {
    background-color: $deep-pink;
  }
}

.loadingPrimary {
  fill: $white;
}

.loadingSecondary {
  fill: $cilantro;
}

@keyframes rotate {
  100% {
    transform: rotate(360deg);
  }
}

.loading {
  line-height: 0;
}
