@import '~@travel-ui/styles/src/utility';

.wrapper {
  position: relative;

  .balloon {
    border-radius: 10px;
    height: auto;
    position: absolute;
    top: calc(100% + #{$spacing-base});
    right: 0;
    width: 320px;
    z-index: 9999;
  }

  .languageButton {
    @include resetButtonStyle;
    align-items: center;
    border-radius: 5px;
    color: $white;
    display: inline-flex;
    font-weight: 700;
    padding: $spacing-m $spacing-s;
    // TODO: Set background color based on the header background

    span {
      padding-left: $spacing-base;
    }

    &:disabled span {
      padding: 0 $spacing-base;
    }

    svg path:last-child {
      fill: $white;
    }
  }
}
