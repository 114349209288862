@import '~@travel-ui/styles/src/utility';

$dialog-components-height: $dialog-header-height + $dialog-actionbar-height;

.content {
  background-color: $white;
  min-height: 160px;
  overflow: auto;
  // 100vh is NOT an actual viewport. It does NOT deduct the browser's navbar height
  // we have to use 100% on SP, TL
  max-height: calc(100% - #{$dialog-components-height});
  @include media(pc) {
    max-height: calc(100vh - #{$dialog-components-height});
  }
}
