@import '~@travel-ui/styles/src/utility';

.gridRow {
  border: 1px solid $isabelline;
  min-height: 78px;

  &:hover {
    background-color: $laurel-green;
  }
}
