@import '~@travel-ui/styles/src/utility';

.drawer {
  display: flex;
  flex-direction: row;

  .drawerContent {
    /** TOOD: use mixin to unify with Drawer */
    box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.2);
    display: flex;
    flex-direction: column;
    margin-bottom: $supplier-fixed-button-height - $supplier-footer-height;
    flex: 1 1 auto;
    width: 100%;
    height: calc(100vh - (#{$supplier-header-height} + #{$supplier-fixed-button-height}));
    overflow: auto;

    .detailTypeHeader {
      background-color: $white;
      padding: $spacing-m;
      z-index: 0;
      box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
      display: flex;
      justify-content: space-between;

      .headerButton {
        width: 180px;
        height: 36px;
      }

      .detailTypeHeaderContent {
        margin-top: $spacing-m;
        display: flex;

        .title {
          height: 20px;
          width: 240px;
          margin-bottom: 16px;
        }

        .content {
          height: 20px;
          width: 120px;
          max-width: 200px;
          margin-bottom: 10px;
        }
      }
    }

    .skeletonList {
      /** TOOD: use mixin to unify with DrawerItemList */
      background-color: $shady-white;
      padding: $spacing-l 100px;
      flex-grow: 1;
      width: 100%;
      max-width: 1384px;
      margin: 0 auto;
    }
  }

  .longButton {
    width: 180px;
  }
}
