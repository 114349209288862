@import '~@travel-ui/styles/src/utility';

// Add your CSS here
.dialog {
  width: 600px;
  z-index: 99999;
}

.contentDialog {
  border-radius: 0 0 10px 10px;
}

.headerDialog {
  border-radius: 10px 10px 0 0;
}

.wrapper {
  @include flex-center;
  height: 224px;
  flex-flow: column;
  width: 100%;
  margin: $spacing-m;

  .titleContainer {
    @include flex-center;
    margin-bottom: $spacing-m;

    .title {
      font-size: $font-solar;
      font-weight: bold;
      line-height: $line-height-title;
      margin-left: $spacing-base;
    }
  }

  .message {
    font-size: $font-venus;
    line-height: $line-height-body;
    margin-bottom: 58px;
    text-align: center;

    .lblGreen {
      color: $cilantro;
    }
  }

  .okButton {
    @include box(180px, 44px);
  }
}
