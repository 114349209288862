@import '~@travel-ui/styles/src/utility';

.wrapper {
  display: flex;
  height: calc(
    100vh - #{$supplier-header-height} - 173px - #{$spacing-m} - #{$supplier-footer-height}
  );
  margin-top: $spacing-m;

  .sidebar {
    width: 190px;
    background-color: $white;

    .listItemSkeleton {
      height: auto;
      border: none;
      overflow: hidden;
      padding: $spacing-m;
      max-width: 180px;
    }
  }
  .content {
    border-left: 1px solid $isabelline;

    .contentHeader {
      height: 46px;
      background-color: $white;
    }

    .table {
      thead {
        td {
          height: 46px;
        }
      }
    }
  }
}
