// -----------------------------------------------------------------------------
// This file contains very basic styles.
// -----------------------------------------------------------------------------

/**
 * Set up a decent box model on the root element
 */
html {
  box-sizing: border-box;
}

html:has(dialog[open][modal-mode='fullScreen']) {
  overflow: hidden;
  isolation: isolate;
  touch-action: none;
  -ms-touch-action: none;
  position: fixed;
  height: 100vh;
}

/**
 * Make all elements from the DOM inherit from the parent box-sizing
 * Since `*` has a specificity of 0, it does not override the `html` value
 * making all elements inheriting from the root box-sizing value
 * See: https://css-tricks.com/inheriting-box-sizing-probably-slightly-better-best-practice/
 */
*,
*::before,
*::after {
  box-sizing: inherit;
}

body {
  @include font-body;
  color: $jet;
  font-family: arial, sans-serif;
}

h1 {
  @include font-title;
}

h2 {
  @include font-sub-title;
}

h3 {
  @include font-category;
}

h4 {
  @include font-label;
}

h5 {
  @include font-sub-label;
}
