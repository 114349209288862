@import '~@travel-ui/styles/src/utility';

.errorPage {
  padding: 40px 140px 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100%;
}

.icon {
  margin-bottom: $spacing-xl;
}

.mainText {
  margin-bottom: $spacing-m;
  max-width: 500px;
  text-align: center;
  font-size: $font-saturn;
  line-height: 24px;
  font-weight: bold;
  color: #767676;
}

.subText {
  color: $sonic-silver;
  text-align: center;
  margin-bottom: $spacing-m;
}
