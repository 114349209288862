@import '~@travel-ui/styles/src/utility';

$sortButtonHeight: 40px;

@mixin filterBarCommon {
  align-items: center;
  display: flex;
  padding: $spacing-base;
  border-radius: $border-radius;
  user-select: none;

  .selected {
    @include font-label;

    color: $cilantro;
    cursor: pointer;
    margin: 0 $spacing-m;
    text-align: center;
  }
}

.sortDropDown {
  height: $sortButtonHeight;
  width: fit-content;

  .filterBar {
    @include filterBarCommon();
  }

  .filterBarDimmed {
    @include filterBarCommon();
    background-color: $pastel-gray;
  }

  .balloonWrapper {
    align-self: flex-end;
    width: 100%;
    position: relative;
    z-index: $zIndex-dropdown;

    .sortBalloon {
      top: $spacing-base;
    }
  }

  .title {
    @include font-label;

    margin-left: $spacing-base;
  }
}
