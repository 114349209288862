@import '~@travel-ui/styles/src/utility';

$full: 100%;
$ball-minH: 120px;
$ball-maxH: 600px;
$ball-maxW: $filter-wrapper-width;
$box-minH: 115px;

.balloon {
  @include box-shadow-middle;
  @include border-radius;

  position: relative;
  width: $full;
  max-width: $ball-maxW;
  height: calc(100% - #{$spacing-s});
  max-height: $ball-maxH;
  background-color: $white;
  border: 1px solid $pastel-gray;
  overflow: hidden;
  top: $spacing-base;
  z-index: $zIndex-overlay2;

  .text {
    position: relative;
    width: 100%;
    z-index: $zIndex-overlay1;
  }
}

.ballMinH {
  min-height: $ball-minH;
  padding: $spacing-m $spacing-base;
}

.textMinH {
  min-height: $box-minH;
}
