@import '~@travel-ui/styles/src/utility';

.fadeIn {
  &Appear {
    opacity: 0.01;
  }

  &AppearActiveshort {
    @include opacity_short;

    opacity: 1;
  }

  &AppearActivelong {
    @include opacity_correspond($max-duration, $linear);

    opacity: 1;
  }

  &Enter {
    opacity: 0.01;
  }

  &EnterActiveshort {
    @include opacity_short;

    opacity: 1;
  }

  &EnterActivelong {
    @include opacity_correspond($max-duration, $linear);
  }

  &Exit {
    opacity: 1;
  }

  &ExitActiveshort {
    @include opacity_short;

    opacity: 0.01;
  }

  &ExitActivelong {
    @include opacity_correspond($max-duration, $linear);

    opacity: 0.01;
  }
}

.slideUp {
  &Appear {
    opacity: 0.01;
  }

  &AppearActiveshort {
    @include opacity_short;

    opacity: 1;
  }

  &AppearActivelong {
    @include opacity_correspond($max-duration, $linear);

    opacity: 1;
  }

  &Enter {
    transform: translateY(100%);
  }

  &EnterActiveshort {
    transition: transform $min-duration ease-in;
    transform: translateY(0);
  }

  &EnterActivelong {
    transition: transform $max-duration ease-in;
    transform: translateY(0);
  }

  &Exit {
    transform: translateY(0);
  }

  &ExitActiveshort {
    transition: transform $min-duration ease-in;
    transform: translateY(100%);
  }

  &ExitActivelong {
    transition: transform $max-duration ease-in;
    transform: translateY(100%);
  }
}

.slideInRight {
  &Enter {
    transform: translateX(-800px);
  }

  &EnterActiveshort {
    @include move_short(transform);

    transform: translateX(0);
  }

  &EnterActivelong {
    @include move_long(transform);

    transform: translateX(0);
  }

  &Exit {
    transform: translateX(0);
  }

  &ExitActiveshort {
    @include move_short(transform);

    transform: translateX(-800px);
  }

  &ExitActivelong {
    @include move_long(transform);

    transform: translateX(-800px);
  }
}

.fadeInDown {
  &Appear {
    opacity: 0.01;
  }

  &AppearActiveshort {
    @include opacity_short;

    opacity: 1;
  }

  &AppearActivelong {
    @include opacity_correspond($max-duration, $linear);

    opacity: 1;
  }

  &Enter {
    transform: translateY(-50px);
    opacity: 0.01;
  }

  &EnterActiveshort {
    transition: transform $min-duration $ease-out-expo, opacity $min-duration ease-in 100ms;
    transform: translateY(0);
    opacity: 1;
  }

  &EnterActivelong {
    transition: transform $max-duration $ease-out-expo, opacity $max-duration ease-in 100ms;
    transform: translateY(0);
    opacity: 1;
  }

  &Exit {
    transform: translateY(0);
    opacity: 1;
  }

  &ExitActiveshort {
    transition: transform $min-duration ease-in, opacity 100ms ease-in;
    transform: translateY(-50px);
    opacity: 0.01;
  }

  &ExitActivelong {
    transition: transform $max-duration ease-in, opacity 100ms ease-in;
    transform: translateY(-50px);
    opacity: 0.01;
  }
}

.zoomIn {
  &Appear {
    opacity: 0.01;
  }

  &AppearActiveshort {
    @include opacity_short;

    opacity: 1;
  }

  &AppearActivelong {
    @include opacity_correspond($max-duration, $linear);

    opacity: 1;
  }

  &Enter {
    transform: scale(0.5, 0.5);
    opacity: 0.01;
  }

  &EnterActiveshort {
    transition: transform $min-duration $ease-out-expo, opacity 200ms ease-in;
    transform: scale(1, 1);
    opacity: 1;
  }

  &EnterActivelong {
    transition: transform $max-duration $ease-out-expo, opacity 200ms ease-in;
    transform: scale(1, 1);
    opacity: 1;
  }

  &Exit {
    transform: scale(1, 1);
    opacity: 1;
  }

  &ExitActiveshort {
    transition: transform $min-duration ease-in, opacity 100ms ease-in;
    transform: scale(0.1, 0.1);
    opacity: 0.01;
  }

  &ExitActivelong {
    transition: transform $max-duration ease-in, opacity 100ms ease-in;
    transform: scale(0.1, 0.1);
    opacity: 0.01;
  }
}

.shake {
  &Enter {
    transform: translate(0, 0) rotate(0);
  }

  &EnterActiveshort {
    animation: shake $min-duration $linear infinite;
  }

  &EnterActivelong {
    animation: shake $max-duration $linear infinite;
  }
}

@keyframes shake {
  0% {
    transform: translate(3px, 2px) rotate(0);
  }

  10% {
    transform: translate(-2px, -3px) rotate(-1deg);
  }

  20% {
    transform: translate(-4px, 0) rotate(1deg);
  }

  30% {
    transform: translate(0, 3px) rotate(0);
  }

  40% {
    transform: translate(2px, -2px) rotate(1deg);
  }

  50% {
    transform: translate(-2px, 3px) rotate(-1deg);
  }

  60% {
    transform: translate(-4px, 2px) rotate(0);
  }

  70% {
    transform: translate(3px, 2px) rotate(-1deg);
  }

  80% {
    transform: translate(-2px, -2px) rotate(1deg);
  }

  90% {
    transform: translate(2px, 4px) rotate(0);
  }

  100% {
    transform: translate(2px, -3px) rotate(-1deg);
  }
}
