@import '~@travel-ui/styles/src/utility';

.gridHead {
  background-color: $isabelline;
  font-weight: bold;
  position: sticky;
  position: -webkit-sticky;
  top: -1px;
  left: 0px;
  z-index: 1;

  tr:hover {
    background-color: $isabelline;
  }

  td {
    background-color: $isabelline;
    word-break: keep-all;
  }
}
