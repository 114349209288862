@import '~@travel-ui/styles/src/utility';

%icon {
  @include box(18px);

  display: inline-block;
  margin: 4px 8px 4px 4px;
}

.container {
  @include break-word;

  user-select: none;
  cursor: pointer;
  display: inline-flex;
  position: relative;

  &:focus-within .checkBoxIcon {
    outline: auto; // ToDo: update once focus design decided
  }

  & > input[type='checkbox'] {
    position: absolute;
    width: 1px;
    height: 1px;
    margin: -1px;
    border: 0;
    overflow: hidden;
    padding: 0;
    clip: rect(0, 0, 0, 0);
  }

  .checkBoxLabel {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  .uncheckedIcon {
    @extend %icon;

    background-color: $white;
    border: 2px solid $pastel-gray;

    svg {
      @include invisible;
    }
  }

  .checkedIcon {
    @extend %icon;

    border: 2px solid $cilantro;
    background-color: $cilantro;

    svg {
      @include key_fadeIn;
      @include motion_fadeIn;
      @include box(25px);

      path {
        fill: $white;
        stroke: $white;
      }

      path:first-child {
        fill: $cilantro;
        stroke: $cilantro;
      }
    }
  }

  .disabledCheckedIcon {
    @extend %icon;

    border: 2px solid $pastel-gray;
    background-color: $isabelline;

    svg {
      path {
        fill: $pastel-gray;
        stroke: $pastel-gray;
      }

      path:first-child {
        fill: $isabelline;
        stroke: $pastel-gray;
        stroke-width: 0;
      }
    }
  }

  .disabled {
    cursor: default;

    .uncheckedIcon {
      background-color: $shady-white;
    }
  }
}

.hidden {
  height: 1px;
  width: 1px;
  position: absolute;
  overflow: hidden;
  top: -10px;
}
