@import '~@travel-ui/styles/src/utility';

.wrapper {
  @include absolute(0, 0, 0, 0);
  @include flex-center;
  width: 100vw;
  height: 100vh;
  word-break: break-word;
  white-space: pre-line;

  @include media(pc) {
    // this is to fix bug on Safari 15 (PC) to force browser repaint
    // Basically it does nothing on UI but rendering process
    transform: translateZ(0);
  }

  &.open {
    z-index: $zIndex-modal;
  }

  &.toggledOpacity {
    &.open {
      opacity: 1;
      z-index: $zIndex-modal;
    }
    &.close {
      opacity: 0;
      z-index: -1;
    }
  }

  &.overlappedDialog {
    &.open {
      // property calc() is not working properly - known issue in IE //
      z-index: $zIndex-modal + 1;
    }
  }
}

.dialog {
  position: relative;
  width: 80vw;
  z-index: $zIndex-modal;
}

.slideUp {
  @include media(sp, tl) {
    animation: slideUp $max-duration $ease-out-expo;
    animation-fill-mode: forwards;
  }
}

@keyframes slideUp {
  0% {
    transform: translateY(50%);
  }
  100% {
    transform: translateY(0);
  }
}

.modalDialog {
  .title {
    @include text-truncate;

    height: $dialog-header-height;
    padding: $spacing-m;
    padding-left: $spacing-m * 3.5;
  }

  .iconButton {
    @include resetButtonStyle;

    padding: $spacing-m;
    position: absolute;
  }

  @include media(sp) {
    display: flex;
    flex-direction: column;
    height: 100%;
    width: 100%;

    .title {
      flex: 0 1 auto;
    }

    .content {
      flex: 1 1 auto;
    }
  }

  @include media(sp) {
    top: 0;
  }
}

.header {
  // need these 2 line to make box-shadow work
  position: relative;
  z-index: 1;

  background-color: $white;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
}

.overlay {
  @include overlay;

  &.isClosable {
    pointer-events: auto;
  }
}

.withFlexBox {
  display: flex;
}

.noAction {
  &#{&} {
    // 100vh is NOT an actual viewport. It does NOT deduct the browser's navbar height
    // we have to use 100% on SP, TL
    max-height: calc(100% - #{$dialog-header-height});

    @include media(pc) {
      max-height: calc(100vh - #{$dialog-header-height});
    }
  }
}
