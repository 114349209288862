@import '~@travel-ui/styles/src/base';
@import '~@travel-ui/styles/src/utility';

$sideBarwidth: 280px;
$appBackground: $shady-white;

@mixin detail {
  color: $blue-raspberry;
  text-decoration: underline;
}

body {
  overflow: hidden;
  background: none;
  visibility: visible;
}

.root {
  height: 100%;
  display: flex;
  flex-direction: row;
}

.appContent {
  position: relative;
  min-height: 100%;
  background: $appBackground;
  flex-grow: 1;
}

.dialogTitle {
  display: flex;
  justify-content: center;
  margin-bottom: $spacing-m;
  padding-right: $spacing-l;
  padding-left: $spacing-l;

  .dialogTitleIcon {
    margin: $spacing-s $spacing-base 0 0;
  }

  .dialogTitleText {
    font-size: $font-solar;
    font-weight: bold;
  }
}

.dialogMessage {
  font-size: $font-venus;
  margin-bottom: $spacing-xl;
}

.lblGreen {
  color: $cilantro;
}

.resetPasswordLink {
  cursor: pointer;
  @include detail;
}

.daysExpire {
  @include detail;
}
