@import '~@travel-ui/styles/src/utility';

.iconTextLink {
  @include resetButtonStyle;
  align-items: center;
  display: inline-flex;
  cursor: pointer;
  font-size: 14px;
  color: $cilantro;

  &:disabled {
    .textWrap {
      color: $pastel-gray;
    }
    svg path[fill] {
      fill: $pastel-gray;
    }
  }
}

.left {
  flex-direction: row;
  justify-content: flex-start;

  .iconWrap {
    margin-right: 5px;
  }

  .textWrap {
    text-align: left;
  }
}

.right {
  flex-direction: row-reverse;
  justify-content: flex-end;

  .iconWrap {
    margin-left: 5px;
  }

  .textWrap {
    text-align: right;
  }
}
