@import '~@travel-ui/styles/src/utility';

.footer {
  background-color: $white;
  color: $black;
  padding: 12px $spacing-m;
  text-align: right;
  border-top: 1px solid $isabelline;

  .content {
    align-items: center;
    display: flex;
    font-size: $font-venus;
    height: 20px;
    justify-content: flex-end;
  }
}
