@import '~@travel-ui/styles/src/utility';

.listItem {
  display: flex;
  justify-content: space-between;
  border: 1px solid $isabelline;
  background-color: $white;
  padding: $spacing-m;
}

.itemContent {
  word-break: break-word;
}

.itemNavigation {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  flex-shrink: 0;

  .tag {
    text-align: center;
    padding: 0;
  }

  .short {
    @include box(60px, 18px);
  }

  .long {
    min-width: 80px;
    height: 18px;
    padding-left: $spacing-base;
    padding-right: $spacing-base;
    font-weight: bold;
  }

  .detailButton {
    min-width: 100px;
    margin-top: auto;
  }

  .subButton {
    margin-top: auto;
    max-width: none;
  }

  .status {
    display: flex;
    align-items: center;
    margin-bottom: 16px;
  }

  .mediaTypeText {
    margin: 0 0 0 5px;
  }

  .gridWidth {
    width: 180px;
    /** Note: `flex: 1;` shorthand for `<flex-grow> 1 <flex-shrink> 1 <flex-basis> 0%` */
    flex: 1;
    /** 
     * Hence at least @IE11 `flex-basis` should be `auto` see bug workaround:
     * https://github.com/philipwalton/flexbugs#7-flex-basis-doesnt-account-for-box-sizingborder-box
     */
    flex-basis: auto;
    text-align: center;
  }
}

.inline {
  flex-direction: row;

  .status {
    margin: 13px $spacing-m 13px 0;
  }
}

.timeStamp {
  color: $sonic-silver;
  font-size: $font-terra;
  margin-right: $spacing-base;
  white-space: nowrap;

  .actionUpdate {
    margin-left: $spacing-s;
  }

  .actionCreate {
    margin-right: $spacing-s;
  }
}

.gridColumn {
  display: flex;
  margin: $spacing-base;
  padding: $spacing-base 0;

  .detailButtonToggle {
    min-width: 164px;
    align-self: center;
  }
}
