@import '~@travel-ui/styles/src/utility';

.listType {
  height: 168px;
  display: flex;
  border: 1px solid $isabelline;
  background-color: $white;
  padding: $spacing-m;

  &.paddingLeft {
    padding-left: 60px;
  }

  .title {
    height: 20px;
    width: 240px;
    margin-bottom: 16px;
  }

  .content {
    height: 20px;
    width: 120px;
    max-width: 200px;
    margin-bottom: 10px;
  }
}

.detailType {
  height: 80px;
  background-color: $white;
  margin-bottom: $spacing-m;
  background-color: $white;
  padding: $spacing-m;
  border-radius: 5px;

  .title {
    height: 16px;
    width: 320px;
    margin-bottom: 16px;
    width: 30%;
  }

  .content {
    height: 16px;
    width: 160px;
    margin-bottom: 10px;
    max-width: 200px;
  }
}
