@import '~@travel-ui/styles/src/utility';

// Add your CSS here
.toolBar {
  background-color: $white;
  display: flex;
  padding: $spacing-m;
  border-bottom: $pastel-gray solid 1px;

  &.block {
    display: block;
  }
}
