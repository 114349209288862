@import '~@travel-ui/styles/src/utility';

.fixedbutton {
  @include fixed(null, 0, 0, 0);
  @include flex-center;
  background-color: $white;
  border-top: 1px solid $isabelline;
  height: $fixed-button-height;
  justify-content: flex-end;
  padding: ($spacing-l - 2) $spacing-l;
  padding-left: 280px;

  * + * {
    margin-left: $spacing-m;
  }
}
