@import '~@travel-ui/styles/src/utility';

.container {
  z-index: 2;
  display: inline-flex;
  padding: $spacing-s;
  margin: $spacing-m;
  position: fixed;
  width: 500px;

  .content {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
  }
}
