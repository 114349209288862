@mixin shadow-1 {
  box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.12), 0px 0px 8px rgba(0, 0, 0, 0.08);
}

@mixin shadow-2 {
  box-shadow: 0px 2px 8px -2px rgba(0, 0, 0, 0.12), 0px 4px 16px rgba(0, 0, 0, 0.08);
}

@mixin shadow-3 {
  box-shadow: 0px 4px 12px -4px rgba(0, 0, 0, 0.14), 0px 8px 24px rgba(0, 0, 0, 0.1);
}

@mixin shadow-4 {
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
}

@mixin shadow-5 {
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.2);
}

@mixin top-down-shadow {
  box-shadow: 0px -2px 8px rgba(0, 0, 0, 0.12), 0px -4px 16px rgba(0, 0, 0, 0.08);
}

$border-radius-2px: 2px;
$border-radius-4px: 4px;
$border-radius-6px: 6px;
$border-radius-8px: 8px;
$border-radius-10px: 10px;
$border-radius-12px: 12px;
$border-radius-16px: 16px;
$border-radius-24px: 24px;
$border-radius-100px: 100px;

@mixin border-radius-all($radius-px) {
  border-radius: $radius-px;
}

@mixin border-radius-top($radius-px) {
  border-radius: $radius-px $radius-px 0 0;
}

@mixin border-radius-left($radius-px) {
  border-radius: $radius-px 0 0 $radius-px;
}

@mixin border-radius-right($radius-px) {
  border-radius: 0 $radius-px $radius-px 0;
}

@mixin border-radius-bottom($radius-px) {
  border-radius: 0 0 $radius-px $radius-px;
}
