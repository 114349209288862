@import '~@travel-ui/styles/src/utility';

.listRoot {
  @include listRoot;
}

.header {
  width: 100%;
  background: $white;
}

.itemHeader {
  font-weight: 600;
  @include header-hover;
}

.filterBalloon {
  .filterTitle {
    padding: $spacing-base 0;
  }
}

.buttonsWrapper {
  @include box-shadow-middle;
  display: flex;
  justify-content: space-between;
  padding: $spacing-m $spacing-base;
  width: 100%;

  .button {
    max-width: 100%;
    width: $filter-action-button-width;
    padding: 0;
    margin: 0 $spacing-base;
  }
}
