@import '~@travel-ui/styles/src/utility';

%thick-border {
  border: 3px solid $white;
}

%thin-border {
  border: 2px solid $pastel-gray;
}

.container {
  display: block;
  position: relative;
  margin-bottom: 12px;
  cursor: pointer;
  font-size: $font-venus;

  &:hover .radio ~ .checkmark {
    border-color: $cilantro;
  }

  &:hover .radio:disabled ~ .checkmark {
    border-color: $cloudy-gray;
  }

  .radio:checked ~ .checkmark::after {
    background-color: $cilantro;
  }
}

.radio {
  position: absolute;
  opacity: 0;

  &:disabled:checked ~ .checkmark {
    &::after {
      background-color: $pastel-gray;
    }
  }
}

.container:focus-within {
  .checkmark {
    outline: auto; // Todo: update once focus design decided
  }
}

.checkmark {
  @include box(20px);
  display: inline-block;
  vertical-align: middle;
  position: relative;
  left: 0;
  border-radius: 50%;
  border: 2px solid $cloudy-gray;
  background: $white;

  &::after {
    @include box(10px);

    box-sizing: border-box;
    content: '';
    position: absolute;
    top: 50%; /* position the top  edge of the element at the middle of the parent */
    left: 50%; /* position the left edge of the element at the middle of the parent */

    transform: translate(-50%, -50%);
    border-radius: 50%;
  }
}

.disabled {
  color: $isabelline;
}
.label {
  vertical-align: middle;
  margin-left: $spacing-08;
}
