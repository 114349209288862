@import '~@travel-ui/styles/src/utility';

.thumbnail {
  height: 135px;
  width: 240px;
  margin-bottom: $spacing-base;
  margin-right: $spacing-m;
}

.skeleton {
  background-color: $isabelline;
  overflow: hidden;
  border-radius: 3px;

  &:before {
    content: '';
    display: block;
    height: 100%;
    width: 100%;
    background: linear-gradient(90deg, transparent 20%, #f1f1f1 50%, transparent 80%);
    animation: skeleton-animation 1s linear infinite;
  }
}

@keyframes skeleton-animation {
  0% {
    transform: translateX(-100%);
  }

  100% {
    transform: translateX(100%);
  }
}
