@import '~@travel-ui/styles/src/utility';

.action {
  &#{&} {
    box-shadow: unset;
    justify-content: flex-end;
    padding: 0 $spacing-24 0 0;
  }

  button {
    height: 36px;
    margin-bottom: 25px;
    min-width: 120px;
    width: unset;
  }
}

.dialogContainer {
  &#{&} {
    width: 552px;
    border-radius: 10px;
    overflow: hidden;
  }
}

.dialogContent {
  &#{&} {
    min-height: 0px;
    text-align: center;
    padding-top: $spacing-12 * 2;
    padding-bottom: $spacing-16;
    font-size: large;
    display: block;
  }

  b {
    font-weight: bolder;
  }
}
