@import '~@travel-ui/styles/src/utility';

$minH: 44px;
$full: 100%;

.balloonMenu {
  display: inline-block;
  height: $full;
  min-height: $minH;
  min-width: 120px;
}

.content {
  display: flex;
  flex-direction: column;
  height: $full;
  margin-left: 0;
  width: $full;

  li {
    align-items: center;
    border-bottom: 1px solid $isabelline;
    color: $jet;
    display: flex;
    flex: 1;
    justify-content: space-between;
    min-height: $minH;
    padding: $spacing-base $spacing-m;
    text-align: left;
    width: $full;
    word-break: break-word;
    background-color: $white;

    &.active {
      color: $cilantro;
      font-weight: bold;
    }

    &:hover {
      background-color: $laurel-green;
      color: $cilantro;
      cursor: pointer;
    }

    &:last-child {
      border-bottom: none;
    }
  }
}

.searchWrapper {
  padding: $spacing-m;
  border-bottom: 1px solid $isabelline;
  position: relative;

  .icon {
    @include box($spacing-l);
    @include absolute(0, $spacing-l, 0, unset);
    margin: auto;
  }
}
