@import '~@travel-ui/styles/src/utility';

.header {
  @include supplier-page-header;
  height: 90px;
  padding: 30px 0 0 30px;

  .title {
    height: 20px;
    width: 200px;
  }
}

.contentWrapper {
  display: flex;
  margin: 0 auto;
  padding: $spacing-l;
  max-width: 1300px;

  .filterSection {
    width: 331px;
    min-width: 331px;
    height: 700px;
    background-color: $white;
    padding: 24px;
    margin-right: $spacing-l;
  }

  .mainContent {
    width: 100%;
    height: 200px;
    background-color: $white;
  }
}
