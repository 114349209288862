@import '~@travel-ui/styles/src/utility';

.gridCell {
  border-top: 1px solid $isabelline;
  border-right: none;
  border-bottom: 1px solid $isabelline;
  border-left: none;
  padding: 16px;
  min-width: 150px;
}
