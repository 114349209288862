@import '~@travel-ui/styles/src/utility';

.listWrapper {
  height: calc(
    100vh -
      (
        #{$supplier-header-height} + #{$supplier-page-header-height} + #{$supplier-toolbar-height} +
          #{$supplier-footer-height}
      )
  );
  overflow: auto;
  // this is the min height on button on flat Button
  padding-bottom: 44px;

  &.fixedButton {
    // TODO: Need to verify the styles
    height: calc(
      100vh -
        (
          #{$supplier-header-height} + #{$supplier-page-header-height} + #{$supplier-toolbar-height} +
            #{$supplier-fixed-button-height}
        )
    );
  }
}

.searchResult {
  font-size: $font-venus;
  padding: $spacing-m;
  height: 52px;
}

.emptyBox {
  height: 80px;
}

.loader {
  background-color: $cilantro;
  height: 4px;

  &:before {
    content: '';
    display: block;
    height: 100%;
    width: 100%;
    background: linear-gradient(90deg, transparent 0%, $sunflower 50%, transparent 100%);
    animation: skeleton-animation 0.6s linear infinite;
  }
}

@keyframes skeleton-animation {
  0% {
    transform: translateX(-100%);
  }

  100% {
    transform: translateX(100%);
  }
}
