@import '~@travel-ui/styles/src/utility';

@mixin menuWrapperCommon {
  display: flex;
  cursor: pointer;
  padding: $spacing-base;
  margin: 0;
  border-radius: $border-radius;
  user-select: none;
}

.filterDropDown {
  height: 40px;
  width: fit-content;

  &::after {
    content: '';
    margin: $spacing-base;
  }
}

.menuWrapper {
  @include menuWrapperCommon();
}

.menuWrapperDimmed {
  @include menuWrapperCommon();
  background-color: $pastel-gray;
}

.balloonWrapper {
  overflow: visible;
  position: absolute;
  z-index: $zIndex-overlay2;
}

.filterBalloon {
  position: relative;
  white-space: nowrap;
  width: auto;
  height: auto;
  top: $spacing-base;
  z-index: $zIndex-overlay2;
}

.title {
  @include font-label;
  margin: 0 0 0 $spacing-base;
}

.buttonsWrapper {
  display: flex;
  justify-content: space-between;

  .button {
    min-width: 49%;
  }
}
