// (Deprecated) Title
@mixin font-title {
  font-size: $font-solar;
  font-weight: bold;
  line-height: $line-height-title;

  @include media(sp) {
    font-size: $font-jupiter;
    line-height: $line-height-title-sp;
  }
}

// (Deprecated) Sub Title
@mixin font-sub-title {
  font-size: $font-saturn;
  font-weight: bold;
  line-height: $line-height-sub-title;

  @include media(sp) {
    font-size: $font-uranus;
    line-height: $line-height-sub-title;
  }
}

// (Deprecated) Category
@mixin font-category {
  font-size: $font-neptune;
  font-weight: bold;
  line-height: $line-height-category;
}

// (Deprecated) Label
@mixin font-label {
  font-size: $font-venus;
  font-weight: bold;
  line-height: $line-height-label;
}

// (Deprecated) Sub Label
@mixin font-sub-label {
  font-size: $font-mars;
  font-weight: bold;
  line-height: $line-height-sub-title;
}

// (Deprecated) Body
@mixin font-body {
  font-size: $font-venus;
  line-height: $line-height-body;
}

// (Deprecated) Sub Body
@mixin font-sub-body {
  font-size: $font-mars;
  line-height: $line-height-sub-body;
}

// (Deprecated) h6 title
@mixin header6-title {
  font-size: $font-venus;
  line-height: $line-height-title;
  font-weight: 700;
}

// (Deprecated) h6 sub title
@mixin header6-sub-title {
  font-size: $font-venus;
  line-height: $line-height-sub-title;
  font-weight: 400;
}

// (Deprecated) Caption12
@mixin caption-12 {
  font-size: $font-mars;
  line-height: $line-height-caption;
  font-weight: 400;
}

// (Deprecated) Caption12 Bold
@mixin caption-12-bold {
  font-size: $font-mars;
  line-height: $line-height-caption;
  font-weight: 700;
}

// (Deprecated) h4
@mixin header-4 {
  font-size: $font-uranus;
  font-weight: bold;
  line-height: $line-height-title-sp;
}

// Design Token start
@mixin bold {
  font-weight: bold;
}

@mixin font-regular-12 {
  font-size: 12px;
  line-height: 18px;
}

@mixin font-pc-display-d1 {
  font-size: 48px;
  line-height: 55px;
}

@mixin font-pc-display-d1-bold {
  @include font-pc-display-d1;
  @include bold;
}

@mixin font-pc-display-d2 {
  font-size: 40px;
  line-height: 46px;
}

@mixin font-pc-display-d2-bold {
  @include font-pc-display-d2;
  @include bold;
}

@mixin font-pc-display-d3 {
  font-size: 36px;
  line-height: 41px;
}

@mixin font-pc-display-d3-bold {
  @include font-pc-display-d3;
  @include bold;
}

@mixin font-pc-header-h1 {
  font-size: 32px;
  line-height: 44px;
}

@mixin font-pc-header-h1-bold {
  @include font-pc-header-h1;
  @include bold;
}

@mixin font-pc-header-h2 {
  font-size: 28px;
  line-height: 40px;
}

@mixin font-pc-header-h2-bold {
  @include font-pc-header-h2;
  @include bold;
}

@mixin font-pc-header-h3 {
  font-size: 24px;
  line-height: 36px;
}

@mixin font-pc-header-h3-bold {
  @include font-pc-header-h3;
  @include bold;
}

@mixin font-pc-header-h4 {
  font-size: 20px;
  line-height: 32px;
}

@mixin font-pc-header-h4-bold {
  @include font-pc-header-h4;
  @include bold;
}

@mixin font-pc-header-h5 {
  font-size: 18px;
  line-height: 28px;
}

@mixin font-pc-header-h5-bold {
  @include font-pc-header-h5;
  @include bold;
}

@mixin font-pc-header-h6 {
  font-size: 16px;
  line-height: 24px;
}

@mixin font-pc-header-h6-bold {
  @include font-pc-header-h6;
  @include bold;
}

@mixin font-sp-header-h1 {
  font-size: 28px;
  line-height: 36px;
}

@mixin font-sp-header-h1-bold {
  @include font-sp-header-h1;
  @include bold;
}

@mixin font-sp-header-h2 {
  font-size: 24px;
  line-height: 30px;
}

@mixin font-sp-header-h2-bold {
  @include font-sp-header-h2;
  @include bold;
}

@mixin font-sp-header-h3 {
  font-size: 20px;
  line-height: 26px;
}

@mixin font-sp-header-h3-bold {
  @include font-sp-header-h3;
  @include bold;
}

@mixin font-sp-header-h4 {
  font-size: 18px;
  line-height: 24px;
}

@mixin font-sp-header-h4-bold {
  @include font-sp-header-h4;
  @include bold;
}

@mixin font-sp-header-h5 {
  font-size: 16px;
  line-height: 22px;
}

@mixin font-sp-header-h5-bold {
  @include font-sp-header-h5;
  @include bold;
}

@mixin font-sp-header-h6 {
  font-size: 14px;
  line-height: 20px;
}

@mixin font-sp-header-h6-bold {
  @include font-sp-header-h6;
  @include bold;
}

@mixin font-pc-body-b1 {
  font-size: 16px;
  line-height: 24px;
}

@mixin font-pc-body-b1-bold {
  @include font-pc-body-b1;
  @include bold;
}

@mixin font-pc-body-b2 {
  font-size: 14px;
  line-height: 20px;
}

@mixin font-pc-body-b2-bold {
  @include font-pc-body-b2;
  @include bold;
}

@mixin font-pc-body-b3 {
  font-size: 20px;
  line-height: 32px;
}

@mixin font-pc-body-b3-bold {
  @include font-pc-body-b3;
  @include bold;
}

@mixin font-sp-body-b1 {
  font-size: 14px;
  line-height: 24px;
}

@mixin font-sp-body-b1-bold {
  @include font-sp-body-b1;
  @include bold;
}

@mixin font-sp-body-b2 {
  font-size: 12px;
  line-height: 20px;
}

@mixin font-sp-body-b2-bold {
  @include font-sp-body-b2;
  @include bold;
}

@mixin font-sp-body-b3 {
  font-size: 16px;
  line-height: 28px;
}

@mixin font-sp-body-b3-bold {
  @include font-sp-body-b3;
  @include bold;
}

@mixin font-pc-captions-c1 {
  font-size: 12px;
  line-height: 16px;
}

@mixin font-pc-captions-c2 {
  font-size: 10px;
  line-height: 14px;
}

@mixin font-pc-captions-c1-bold {
  @include font-pc-captions-c1;
  @include bold;
}

@mixin font-sp-captions-c1 {
  @include font-pc-captions-c1;
}

@mixin font-sp-captions-c2 {
  @include font-pc-captions-c2;
}

@mixin font-sp-captions-c1-bold {
  @include font-sp-captions-c1;
  @include bold;
}

@mixin font-sp-captions-c2-bold {
  @include font-sp-captions-c2;
  @include bold;
}

@mixin font-pc-price-p1 {
  @include font-pc-header-h1;
}

@mixin font-pc-price-p1-bold {
  @include font-pc-price-p1;
  @include bold;
}

@mixin font-pc-price-p2 {
  @include font-pc-header-h2;
}

@mixin font-pc-price-p2-bold {
  @include font-pc-price-p2;
  @include bold;
}

@mixin font-pc-price-p3 {
  @include font-pc-header-h3;
}

@mixin font-pc-price-p3-bold {
  @include font-pc-price-p3;
  @include bold;
}

@mixin font-pc-price-p4 {
  @include font-pc-header-h4;
}

@mixin font-pc-price-p4-bold {
  @include font-pc-price-p4;
  @include bold;
}

@mixin font-pc-price-p5 {
  @include font-pc-header-h5;
}

@mixin font-pc-price-p5-bold {
  @include font-pc-price-p5;
  @include bold;
}

@mixin font-pc-price-p6 {
  @include font-pc-header-h6;
}

@mixin font-pc-price-p6-bold {
  @include font-pc-price-p6;
  @include bold;
}

@mixin font-pc-price-p7 {
  font-size: 14px;
  line-height: 20px;
}

@mixin font-pc-price-p7-bold {
  @include font-pc-price-p7;
  @include bold;
}

@mixin font-pc-price-p8 {
  font-size: 12px;
  line-height: 16px;
}

@mixin font-pc-price-p8-bold {
  @include font-pc-price-p8;
  @include bold;
}

@mixin font-pc-price-discount {
  text-decoration: line-through;
}
