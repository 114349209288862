@import '~@travel-ui/styles/src/utility';

.wrapper {
  padding-bottom: 3 * $spacing-xl;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  flex-grow: 1;

  .iconWrapper {
    margin-top: 100px;
  }

  .title {
    font-weight: bold;
    margin-bottom: $spacing-s;
    font-size: $font-neptune;
  }

  .desc {
    font-size: $font-venus;
  }
}
