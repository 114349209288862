@import '~@travel-ui/styles/src/utility';

.divider {
  width: 100%;
  border-color: $isabelline;
  border-width: 1px 0 0 0;
}

.solid {
  border-style: solid;
}

.dotted {
  border-style: dotted;
}

.dashed {
  border-style: dashed;
}
