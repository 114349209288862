@import '~@travel-ui/styles/src/utility';

.expandMenu {
  height: 100vh;
  background: $white;
  color: $jet;
  display: flex;
  flex-direction: column;
  border-right: 1px solid $pastel-gray;
  flex: 0 0 auto;
  width: $supplier-sidebar-width;
  transition: width ease 0.3s;
  z-index: $zIndex-overlay1;

  .suspendedContent {
    width: auto;
    white-space: normal;
  }

  .searchBox {
    display: flex;
  }

  .openedSubMenu {
    display: block;
  }
  .nav {
    padding: $spacing-08 $spacing-16 $spacing-16;
  }
  .icon {
    margin-right: 10px;
  }
  .rootNav {
    padding: $spacing-16 $spacing-16 0;
  }
  .menuItemParent {
    padding: $spacing-08;
  }
  .menuButton {
    margin-left: $spacing-08;
    margin-right: $spacing-16;
  }
  .expandMenuButton {
    left: 256px;
  }
  .divider {
    margin-top: $spacing-16;
  }
  .menuItem {
    padding: $spacing-08;
  }
  .subHeader {
    max-height: 100%;
  }
}

.collapseMenu {
  height: 100vh;
  background: $white;
  color: $jet;
  display: flex;
  flex-direction: column;
  border-right: 1px solid $pastel-gray;
  flex: 0 0 auto;
  transition: width ease 0.3s;
  z-index: $zIndex-overlay1;
  width: $supplier-collapsedSideMenuWidth;
  box-sizing: border-box;
  .suspendedContent {
    display: none;
    white-space: nowrap;
    width: 0;
    margin: 0;
  }
  .searchBox {
    display: none;
  }
  .footerLink {
    visibility: hidden;
  }
  .expandMenuButton {
    left: 52px;
  }
  .nav {
    padding: $spacing-08;
    gap: $spacing-04;
    display: flex;
    flex-direction: column;
  }
  .parentNav {
    @include border-radius-all($border-radius-8px);
    width: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 50px;
  }
  .menuItemParent {
    width: 50px;
    height: 50px;
    justify-content: center;
    align-items: center;
  }
  .parentNav:hover {
    background: $shady-white;
  }
  .headSection {
    border-bottom: none;
    height: 56px;
    display: flex;
    justify-content: center;
    padding: 0;
  }
}

.rootNav {
  padding: $spacing-08 $spacing-08 0;

  line-height: 1.7;
  &.paddingTop {
    padding-top: $spacing-l;
  }

  .toggleContent {
    @include border-radius;
    background-color: $black;
    opacity: 0.1;
    margin-top: $spacing-m;
    padding: $spacing-m $spacing-base;
  }

  .headSection {
    font-size: $font-neptune;
    padding: 0 $spacing-08;
    &.text-gap {
      gap: $spacing-16;
    }
    .heading {
      display: flex;
      font-weight: 700;
      justify-content: space-between;
      flex-direction: column;
      line-height: 20px;
      p {
        word-break: break-all;
        @include text-truncate(4);
      }
    }
  }

  .returnBtn {
    min-width: 100%;
    margin-top: $spacing-12;
    @include font-pc-body-b1-bold;
  }

  .provider {
    font-size: $font-mars;
    line-height: 18px;
  }

  .updated {
    font-size: $font-mars;
    color: $cilantro;
  }
}
.nav {
  flex-grow: 1;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
}
.icon {
  width: 20px;
  height: 20px;
}

.parentNav {
  cursor: pointer;
  .menuItemParent {
    @include border-radius-all($border-radius-4px);
    line-height: 24px;
    display: flex;
    font-size: $font-neptune;
  }

  .menuItem {
    display: flex;
    font-size: $font-neptune;
    cursor: pointer;
    @include border-radius-all($border-radius-4px);
    align-items: center;
  }
  &.selected {
    .menuItemParent,
    .menuItem {
      color: $cilantro;
      transition: color linear 0.2s;
    }
    .icon {
      color: $cilantro;
    }
    &:hover {
      .menuItemParent,
      .menuItem {
        background: none;
      }
    }
  }

  &.disabled,
  &.disabled:hover {
    color: $pastel-gray;

    .menuItem {
      color: $pastel-gray;
    }

    .menuItemParent {
      color: $pastel-gray;
    }
  }

  .subMenuItem {
    margin-top: $spacing-04;
    background-color: none;
    border-radius: $border-radius;
    &:hover {
      background: $shady-white;
    }
    &.disabled,
    &.disabled:hover {
      color: $pastel-gray;
    }
  }
}

.menuItemParent:hover {
  background: $shady-white;
}

.menuItem:hover {
  background: $shady-white;
}

.subLink {
  padding: $spacing-08 $spacing-04 $spacing-08 40px;
  @include font-pc-body-b2;
  cursor: pointer;
  display: block;
}

.linkSelected {
  background: $laurel-green;
  @include border-radius-all($border-radius-4px);
  font-weight: bold;
}

.subMenu,
.openedSubMenu {
  display: none;
}

.footerItem {
  margin-top: $spacing-m;
  font-size: $font-neptune;

  .footerLink {
    cursor: pointer;
    display: flex;
    align-items: center;
  }

  &:hover {
    color: $cilantro;
    .masterAssetsIconStyle path:not(:first-child),
    .questionIconStyle g > g > g,
    .learningIconStyle path:last-child {
      fill: $cilantro;
    }

    .learningIconStyle {
      path:not(:first-child):not(:last-child),
      rect {
        stroke: $cilantro;
      }
    }
  }
}

$clearButtonWidth: 24px;

@mixin showClearButton {
  cursor: pointer;
  opacity: 1;
  pointer-events: auto;
}

@mixin hideClearButton {
  opacity: 0;
  pointer-events: none;
}

.text {
  display: inline-block;
  width: 100%;
  height: 44px;
  border: 1px solid $pastel-gray;
  border-radius: $border-radius;
  padding: 0 $spacing-base;
  outline: 0;
  color: $jet;

  @include font-body;

  &:focus,
  &:active {
    border-color: $cilantro;
  }

  &.disabled {
    background: $isabelline;
    color: $pastel-gray;

    &:focus,
    &:active,
    &:hover {
      border-color: $pastel-gray;
    }
  }

  &.invisibleClearButton:hover ~ .clearButton,
  &.invisibleClearButton:focus ~ .clearButton,
  &.invisibleClearButton ~ .clearButton:hover {
    @include showClearButton();
  }

  &.invisibleClearButton:hover,
  &.invisibleClearButton:focus {
    padding-right: calc(#{$spacing-base} + #{$clearButtonWidth});
  }

  &.invisibleClearButton ~ .clearButton {
    @include hideClearButton();
  }

  &.visibleClearButton {
    padding-right: calc(#{$spacing-base} + #{$clearButtonWidth});

    ~ .clearButton {
      @include showClearButton();
    }
  }

  &.visibleClearButtonTime {
    ~ .clearButton {
      @include showClearButton();
    }
  }
}

.clearButton {
  margin: auto;
  pointer-events: visible;

  @include box($spacing-l);
  @include absolute(0, $spacing-base, 0, unset);
}

.navFooter {
  padding: $spacing-l $spacing-m;
}
.menuIcon {
  color: $jet;
  width: 24px;
  height: 24px;
  &#{&} {
    margin-right: 0;
  }
  &:hover {
    color: $cilantro;
  }
}
.menuButton {
  color: $jet;
}
.expandMenuButton {
  position: fixed;
  bottom: 40px;
  background: $olivine;
  border-radius: 100%;
  padding: 6px;
  border: none;
  cursor: pointer;
  z-index: $zIndex-overlay1;
  border: 1px solid $pastel-gray;
  svg g g path:nth-child(2) {
    fill: $lincoln-green;
  }
}
.subHeader {
  display: flex;
  align-items: center;
}
.providerHeader {
  display: flex;
  flex-direction: column;
  gap: $spacing-02;
}
.selectedMenu {
  font-weight: 700;
}
.closeMenuIcon {
  margin-right: 11px;
}
.divider {
  border-bottom: 1px solid $pastel-gray;
  margin-top: $spacing-08;
}
.navMenuDivider {
  border-bottom: 1px solid $isabelline;
  margin: $spacing-08 0;
}
.footerDivider {
  border-bottom: 1px solid $isabelline;
  margin-bottom: $spacing-12;
}
.expandMenuButton:hover {
  background: $cilantro;
  svg g g path:nth-child(2) {
    fill: $white;
  }
  .tooltipText {
    visibility: visible;
  }
}
.tooltipText {
  width: 100px;
  visibility: hidden;
  background: $isabelline;
  color: $black;
  text-align: center;
  @include border-radius-all($border-radius-4px);
  padding: $spacing-02 $spacing-04;
  position: absolute;
  z-index: $zIndex-overlay2;
  bottom: -21px;
  transform: translateX(-50%);
  font-size: 10px;
}

.parentNav.selected {
  .fullPathFill path,
  .allButFirstPathFill path:not(:first-child),
  .innerGraphicsFill g > g,
  .financeManagementStrokes path:nth-child(-n + 2) {
    fill: $cilantro;
  }

  .financeManagementStrokes {
    circle,
    path:nth-child(n + 3) {
      stroke: $cilantro;
    }
  }
}
