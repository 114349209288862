// Follows the design system Figma
// https://www.figma.com/file/pSOhdjrPJ0kaYtsVy6GJZg/Design-Foundations?node-id=2%3A324

// Primary
$cilantro: #54822b;
$lincoln-green: #255500;
$olivine: #b3cf9b;
$laurel-green: #edf2e9;
$cultured: #f7faf4;
$light-pastel-green: #dfead6;

// Secondary
$scarlet: #ce2b18;
$crimson: #b51f20;
$shady-cardinal: #9e0004;
$light-salmon-pink: #eb9f96;
$ruddy-pink: #fbebea;
$lavender-blush: #fcefee; // to be #fcf8f8

$sunflower: #ffba00;
$chocolate: #bb5400;
$flavescent: #ffdc7f;
$mellow-yellow: #fff8e5;

$blue-raspberry: #007cb5;
$current-link-blue: #1d54a7;
$current-link-deep-blue: #13366a;
$dark-sky-blue: #7fbdda;
$lady-blue: #f2f8fb;

$ultraviolet: #8469b0;
$cyber-grape: #553e80;
$wild-blue-yonder: #c1b4d7;

// Gray scales / Neutrals
$black: #000000;
$jet: #333333;
$sonic-silver: #6d6d6d;
$cloudy-gray: #8c8c8c;

$pastel-gray: #cccccc;
$isabelline: #eaeaea;
$shady-white: #f3f3f3;
$white: #ffffff;
$light-grey: #f5f6f7;
$disabled-background: #d1d1d1;
$disabled-text: #9c9c9c;

// Gradients
// #000000 0% to #000000 50%
$gradient-1: linear-gradient(rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.5));

// System / Semantic
$success-background: #edf2e9;
$info-background: #ebf7fe;
$notice-background: #fff8e5;
$warning-background: #ffed90;
$danger-background: #fbebea;

$success-text: #255500;
$info-text: #13366a;
$notice-text: #bb5400;
$warning-text: #7d0000;
$danger-text: #9e0004;

// Brand
$brand-crimson-red: #bf0000;
$brand-green-600-logo: #00b900;
$brand-japan-standard: #d10000;

// in used --> to be unify
$apple-red: #d7402e;
$salmon: #f9e8e8;
$light: #eeeeee;
$opaque-black: rgba(0, 0, 0, 0.1);
$light-red: #bc210f;

// in used --> to be deprecated
$info-blue: #ebf7fe; // $info-background
$mellow-text: #9e0004; // $danger-text
$dark-cyan: #1d54a7; // $current-link-blue
$deep-blue: #13366a; // $current-link-deep-blue
$alice-blue: #f2f8fb; // $lady-blue

// Rakuten mobile
$bright-pink: #ff008c;
$deep-pink: #CC0070;