@import '~@travel-ui/styles/src/utility';

.createButton {
  &#{&} {
    @include box(180px, 44px);
    margin: $spacing-base 0;

    svg > g > g {
      fill: none;
    }
  }
}

.plusIcon {
  path:last-child {
    stroke: $white;
    fill: $white;
  }
}
