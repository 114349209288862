@import '~@travel-ui/styles/src/utility';

/** Single Element CSS Spinners (MIT)
 *  Created by @lukehaas
 *  https://projects.lukehaas.me/css-loaders/ */

.pulse,
.pulse:before,
.pulse:after {
  border-radius: 50%;
  width: 1.8em;
  height: 1.8em;
  top: -20px;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
  -webkit-animation: load7 1s infinite ease-in-out;
  animation: load7 1s infinite ease-in-out;
}

.pulse {
  color: #b9de9c;
  font-size: 9px;
  margin: 20px auto;
  position: relative;
  text-indent: -9999em;
  -webkit-transform: translateZ(0);
  -ms-transform: translateZ(0);
  transform: translateZ(0);
  -webkit-animation-delay: -0.16s;
  animation-delay: -0.16s;
}

.pulse:before,
.pulse:after {
  content: '';
  position: absolute;
  top: 0;
}

.pulse:before {
  left: -3.5em;
  -webkit-animation-delay: -0.32s;
  animation-delay: -0.32s;
}

.pulse:after {
  left: 3.5em;
}

@-webkit-keyframes load7 {
  0%,
  80%,
  100% {
    box-shadow: 0 2.5em 0 -1.3em;
  }

  40% {
    box-shadow: 0 2.5em 0 0;
  }
}

@keyframes load7 {
  0%,
  80%,
  100% {
    box-shadow: 0 2.5em 0 -1.3em;
  }

  40% {
    box-shadow: 0 2.5em 0 0;
  }
}
