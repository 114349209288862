@import '~@travel-ui/styles/src/utility';

.wrapper {
  @include flex-center;
  @include box-shadow-top;

  background-color: $white;
  padding: $spacing-base;

  &.withTertiary {
    justify-content: flex-end;
    padding: $spacing-m;
  }
}

.mainButton {
  display: flex;
  width: auto;
}

.dialogButton {
  max-width: 50%;
  width: 100%;

  @include media(sp) {
    max-width: 100%;
  }

  &:not(:last-child) {
    margin-right: $spacing-base;
  }
}
