@import '~@travel-ui/styles/src/utility';

.toolBar {
  height: 57px;
}

.searchResult {
  height: 52px;
}

.skeletonItem {
  height: 85px;
}

.grid {
  margin: $spacing-m;
}

.pageHeader {
  padding-top: 60px;
}
