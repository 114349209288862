@import '~@travel-ui/styles/src/utility';

.wrapper {
  padding: $spacing-m;
  display: flex;
  flex-direction: column;
  height: 100%;

  .sorry {
    @include font-category;
    margin-bottom: $spacing-base;
  }

  .method {
    @include font-body;
    display: flex;
    margin-bottom: $spacing-base;

    .icon {
      margin: auto $spacing-base;
    }
  }

  .zeroCount {
    margin-bottom: $spacing-m;
  }

  .noResultIcons {
    display: inline-block;
    transform: translateY(20%);
  }

  .mainIcon {
    align-self: center;
    flex-grow: 1;
    padding-bottom: 2 * $spacing-xl;
    margin-top: 100px;
  }
}
