@import '~@travel-ui/styles/src/utility';

.container {
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;

  .searchBar {
    align-items: center;
    border: 1px solid $pastel-gray;
    border-radius: $border-radius;
    display: flex;
    width: 100%;

    .conditionButton {
      @include resetButtonStyle;

      border-left: solid 1px $pastel-gray;
      display: flex;
    }

    .conditionIcon {
      margin: 0 $spacing-base;
    }

    .singleArrowButton {
      @include resetButtonStyle;

      display: flex;
      align-items: center;
      width: 44px;
      height: 44px;

      &:hover {
        background-color: $cilantro;
        svg {
          g > path:nth-child(2) {
            fill: $white;
          }
        }
      }
    }

    .searchReady {
      background-color: $cilantro;
      svg {
        g > path:nth-child(2) {
          fill: $white;
        }
      }
    }

    .singleArrowIcon {
      margin: 0 $spacing-base;
    }
  }

  .balloonWrapper {
    position: relative;
    margin-left: $spacing-s;
    overflow: visible;
  }

  .balloon {
    position: absolute;
    white-space: nowrap;
    width: auto;
    height: auto;
    left: 75px;
    z-index: 1;
  }
}
