@import '~@travel-ui/styles/src/utility';

.bgGreen {
  background-color: $cilantro;
}
.bgLincolnGreen {
  background-color: $lincoln-green;
}
.bgBlack {
  background-color: $jet;
}

.bgWhiteGreen {
  background-color: #8cb36a;
}
.bgWhiteLincolnGreen {
  background-color: #6b8a53;
}
.bgGray {
  background-color: #6d6d6d;
}
