@import '~@travel-ui/styles/src/utility';

.createPageHeader {
  background-color: $white;
  padding: $spacing-l $spacing-m $spacing-base;
  border-bottom: 1px solid $pastel-gray;

  .main {
    display: flex;
    justify-content: space-between;

    .searchSection {
      .searchInput {
        @include box(336px, auto);
        margin: $spacing-base 0;
      }
    }
  }

  .children {
    padding-top: $spacing-m;
  }
}

.breadcrumbs {
  margin: $spacing-base 0;
}

.pageTitle {
  @include font-title;
  margin: $spacing-base 0;
}

.clearButton {
  position: absolute;
  right: $spacing-base;
  pointer-events: visible;

  @include box(24px);
}
