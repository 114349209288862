//Basic Animations
$ease-out-expo: cubic-bezier(0.19, 1, 0.22, 1);
$linear: cubic-bezier(0, 0, 1, 1);
$max-duration: 300ms;
$min-duration: 150ms;

@mixin move_short($property) {
  transition: $property $min-duration $ease-out-expo;
}

@mixin move_long($property) {
  transition: $property $max-duration $ease-out-expo;
}

@mixin opacity_short {
  transition: opacity $min-duration $linear;
}

@mixin opacity_correspond($duration, $timing-function) {
  transition: opacity $duration $timing-function;
}

@mixin move_distance($property, $movingDistance, $referenceValueType) {
  $durationTime: $movingDistance / ($referenceValueType * $max-duration);
  @if $durationTime < $min-duration {
    transition: $property $min-duration $ease-out-expo;
  } @else if $durationTime > $max-duration {
    transition: $property $max-duration $ease-out-expo;
  } @else {
    transition: $property $durationTime $ease-out-expo;
  }
}
