@import '~@travel-ui/styles/src/utility';

.checkboxContainer {
  .label {
    @include font-label;
    display: block;
    margin-bottom: 11px;
  }
  .checkboxWrapper {
    display: flex;
    list-style: none;
    margin-left: 0;
  }
}

.displayRow {
  flex-direction: row;
}

.displayColumn {
  flex-direction: column;
}
