@import '~@travel-ui/styles/src/utility';

.alertWrapper {
  width: 100%;
  position: relative;
  padding: $spacing-m;
  margin: $spacing-s 0 $spacing-m;

  &.isClose {
    padding: 0px $spacing-m;
    height: 0;
    overflow: hidden;
    transition: 0.1s linear;
    transition-property: height, margin-top, margin-bottom, padding-top, padding-bottom;
  }

  .titleWrapper {
    display: flex;
    flex-direction: row;
    margin-bottom: $spacing-s;
  }

  .title {
    @include break-word;
    @include font-label;
  }

  .errorMessage {
    color: $apple-red;
    font-weight: bold;
    margin: -2px $spacing-s;
  }

  .description {
    margin-left: $spacing-l;
    color: $sonic-silver;
  }

  .icon {
    display: flex;
    align-items: center;
    margin-right: $spacing-s;
  }

  .closeButton {
    position: absolute;
    right: 10px;
    top: 10px;
  }
}

.success {
  background-color: $success-background;
  color: $success-text;

  .icon {
    :nth-child(2) > :nth-child(2) {
      fill: $success-text;
    }
  }

  .closeButton {
    :first-child > :nth-child(2) {
      fill: $success-text;
    }
  }

  .description {
    color: $success-text;
  }
}

.error {
  background-color: $danger-background;
  color: $mellow-text;

  .icon {
    :nth-child(2) > :nth-child(2) {
      fill: $mellow-text;
    }
  }

  .closeButton {
    :first-child > :nth-child(2) {
      fill: $mellow-text;
    }
  }

  .description {
    color: $shady-cardinal;
  }
}

.info {
  background-color: $info-blue;
  color: $info-text;

  .icon {
    :nth-child(2) > :nth-child(2) {
      fill: $info-text;
    }
  }

  .closeButton {
    :first-child > :nth-child(2) {
      fill: $info-text;
    }
  }

  .description {
    color: $deep-blue;
  }
}

.warning {
  background-color: $warning-background;
  color: $warning-text;

  .icon {
    :nth-child(2) > :nth-child(2) {
      fill: $warning-text;
    }
  }

  .closeButton {
    :first-child > :nth-child(2) {
      fill: $warning-text;
    }
  }

  .description {
    color: $warning-text;
  }
}

.warning {
  background-color: $warning-background;
}
