@import '~@travel-ui/styles/src/utility';

.container {
  @include flex-center;
  justify-content: space-between;
  height: $supplier-header-height;
  padding: $spacing-base $spacing-m;
  z-index: $z-index-header;

  .menuButton {
    .menuIcon {
      g > g > g > path {
        fill: $white;
      }
    }
  }

  .logo {
    align-items: center;
    display: flex;
    margin: 0 auto 0 $spacing-m;
    padding-top: 6px;
    text-decoration: none;

    svg {
      path {
        fill: $white;
      }
    }

    .envName {
      margin-left: $spacing-m;
      padding: 5px $spacing-m;
      font-weight: bold;
      color: $jet;
      background-color: #ffba00;
      border-radius: 30px;
    }
  }

  .userInfo {
    color: $white;
    margin-right: $spacing-m;
    border-left: 1px solid $lincoln-green;
    padding-left: $spacing-m;
    
    .userName {
      color: $white;
      display: block;
      text-decoration: underline;
    }
  }

  .logoutButton {
    .logoutIcon {
      g > path:nth-child(2),
      g > path:last-child {
        fill: $white;
      }
    }
  }
}

@include media(sp) {
  .container {
    height: 44px;
    padding: 10px $spacing-base;

    .logo {
      margin: 0 auto 0 $spacing-base;
      padding: 0;
    }

    .userInfo {
      display: none;
    }
  }
}
