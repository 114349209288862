@import '~@travel-ui/styles/src/utility';

$validatedIconWidth: 24px;
$clearButtonWidth: 24px;

@mixin showClearButton {
  cursor: pointer;
  opacity: 1;
  pointer-events: auto;
}

@mixin hideClearButton {
  opacity: 0;
  pointer-events: none;
}

.container {
  display: inline-block;

  @include font-body;

  p,
  span,
  label {
    @include break-word;
    font-weight: bold;
    margin-bottom: $spacing-s;
  }

  & > *:not(:last-child) {
    display: inline-block;
    margin-bottom: $spacing-s;
  }

  .textContainer {
    display: flex;
    align-items: center;
    width: 100%;

    .inputContainer {
      display: flex;
      align-items: center;
      width: 100%;
      position: relative;

      &.hasUnit {
        width: 103px;

        @include media(sp) {
          width: 33%;
        }
      }

      &:not(:last-child) {
        margin-right: $spacing-s;
      }

      &:hover > .text {
        border-color: $cilantro;
      }

      &:hover > .invisibleClearButton {
        padding-right: calc(#{$spacing-base} + #{$validatedIconWidth});
      }

      .text {
        display: inline-block;
        width: 100%;
        height: 44px;
        border: 1px solid $pastel-gray;
        border-radius: $border-radius;
        padding: 0 $spacing-base;
        outline: 0;
        color: $jet;

        &::-ms-clear {
          display: none;
        }

        &:focus,
        &:active {
          border-color: $cilantro;
        }

        &.disabled {
          background: $isabelline;
          color: $pastel-gray;

          &:focus,
          &:active,
          &:hover {
            border-color: $pastel-gray;
          }
        }

        &.hasError {
          border-color: $apple-red;
        }

        &.showSuccessMessage {
          border-color: $cilantro;
        }

        &.isValidated {
          padding-right: calc(#{$spacing-base} + #{$validatedIconWidth});
        }

        &.withLeadingIcon {
          padding-left: 38px;
        }

        &.withTrailingIcon {
          padding-right: 38px;
        }

        &.invisibleClearButton:hover ~ .clearButton,
        &.invisibleClearButton:focus ~ .clearButton,
        &.invisibleClearButton ~ .clearButton:hover {
          @include showClearButton();
        }

        &.invisibleClearButton:hover,
        &.invisibleClearButton:focus {
          padding-right: calc(#{$spacing-base} + #{$clearButtonWidth});
        }

        &.invisibleClearButton ~ .clearButton {
          @include hideClearButton();
        }

        &.visibleClearButton {
          padding-right: calc(#{$spacing-base} + #{$clearButtonWidth});

          ~ .clearButton {
            @include showClearButton();
          }
        }

        &.visibleClearButtonTime {
          ~ .clearButton {
            @include showClearButton();
          }
        }
      }

      .highlight {
        background-color: $mellow-yellow;
        border: 1px solid $sunflower;
      }

      .leadingIcon {
        margin: auto;

        @include box($spacing-l);
        @include absolute(0, unset, 0, $spacing-base);
      }

      .trailingIcon {
        margin: auto;
        @include box($spacing-l);
        @include absolute(0, $spacing-base, 0, unset);
      }
    }

    .unit {
      hyphens: none;
    }
  }

  &.fullWidth {
    width: 100%;
  }

  &.multiple {
    width: 50%;
    margin-right: $spacing-base;

    @include media(sp) {
      width: 100%;
      margin-right: 0;
      margin-bottom: $spacing-m;
    }
  }

  &.middle {
    max-width: 100%;
    min-width: 214px;

    @include media(sp) {
      width: 100%;
    }
  }

  .validatedIcon {
    position: absolute;
    right: $spacing-base;

    @include box(24px);
  }

  .clearButton {
    margin: auto;
    pointer-events: visible;

    @include box($spacing-l);
    @include absolute(0, $spacing-base, 0, unset);
  }

  .successMessage {
    color: $cilantro;
  }
}

.multiContainer {
  display: flex;

  @include media(sp) {
    display: block;
  }
}

.hidden {
  height: 1px;
  width: 1px;
  position: absolute;
  overflow: hidden;
  top: -10px;
}
