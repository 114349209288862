@import '~@travel-ui/styles/src/utility';

.actionButton {
  max-width: 100%;
  width: $filter-action-button-width;
  padding: 0;
  margin: 0 ($spacing-s * 2);
}

.actionsButtonWrapper {
  display: flex;
  justify-content: space-between;
  margin: $spacing-m ($spacing-s * 2);

  .button {
    min-width: 49%;
  }
}

.checkboxLabel {
  white-space: normal;
}
