@import '~@travel-ui/styles/src/utility';

$contentMarginTop: 60px;

.headerBar {
  overflow: hidden;
  background-color: $jet;
  position: fixed;
  top: 0;
  width: 100%;
  height: $supplier-header-height;
  display: flex;
  padding-left: $spacing-m;

  .burgerMenuContainer {
    display: inline-block;
    cursor: pointer;
    margin-top: 10px;
  }

  .menuBar {
    width: $spacing-xl;
    height: $spacing-s;
    background-color: $white;
    margin: 6px 0;
  }

  a {
    float: left;
    display: block;
    color: $white;
    text-align: center;
    padding: $spacing-m;
    text-decoration: none;
    cursor: default;
    font-size: x-large;
  }

  a:hover {
    background-color: $sonic-silver;
  }
}

.footerBar {
  height: $contentMarginTop + $spacing-base;
  width: 100%;
  background-color: $pastel-gray;
  color: $black;
  text-align: center;
}

.mainContent {
  position: relative;
  height: calc(100vh - (#{$supplier-header-height} + #{$supplier-footer-height}));
  overflow: auto;
  min-width: 100%;
  width: calc(100vw - #{$supplier-sidebar-width});

  &.menuClosed {
    width: calc(100vw - $supplier-collapsedSideMenuWidth);
  }
  @media print {
    overflow: visible;
  }
}
